import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import './AboutPage.css'
import ProfileSection from '../lib/ProfileSection'

// Export Template for use in CMS preview
export const AboutPageTemplate = ({ title, subtitle, featuredImage }) => (
  <main className="AboutMe">
    <Helmet>
      <meta name="twitter:image" content={featuredImage} />
      <meta property="og:image:secure_url" content={featuredImage} />
      <meta property="og:image" content={featuredImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <script src="https://code.iconify.design/1/1.0.7/iconify.min.js" />
    </Helmet>
    <section className="AboutMesection">
      <div className="sectionName">
        <h1> About Me</h1>
      </div>
      <div className="container">
        <ProfileSection />
      </div>
    </section>
    {/*<section className="Experiencesection section">
    <div className="sectionName">
      <h1> Works & Experience</h1>
    </div>
      <div className="container">
        <WorksSectionOne/>
      </div>
    </section>*/}
  </main>
)

// Export Default AboutPage for front-end
const AboutPage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <AboutPageTemplate {...page} {...page.frontmatter} />
  </Layout>
)

export default AboutPage

export const pageQuery = graphql`
  ## Query for AboutPage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query AboutPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
      }
    }
  }
`
