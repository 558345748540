import React from 'react'

import Avatar from '@material-ui/core/Avatar'
import IlhamAvatar from '../../static/images/ilham.jpg'
import {
  Twitter,
  Instagram,
  Facebook,
  LinkedinBox,
  Spotify,
  Github
} from '@styled-icons/remix-line'
import { Reactjs, Html5, Css3, Gatsby } from '@styled-icons/remix-fill'
import {
  Javascript,
  Mongodb,
  Graphql,
  Nginx,
  Linux,
  Adobephotoshop,
  NextDotJs,
  Redux,
  Tailwindcss,
  Spring
} from '@styled-icons/simple-icons/'
import {
  Netlify,
  Php,
  Amazonaws,
  Java,
  Mysql,
  Apache,
  Googlecloud,
  Heroku,
  Cplusplus,
  Npm,
  Yarn,
  Woo,
  Bootstrap,
  Vercel
} from '@styled-icons/simple-icons/'
import { Wordpress } from '@styled-icons/icomoon/'
import { Git, Nodejs } from '@styled-icons/boxicons-logos/'
import Fade from 'react-reveal/Fade'

const ProfileSection = () => {
  return (
    <div className="ProfileSection">
      <Fade bottom cascade>
        <div className="ProfileSection--Grid">
          <div className="ProfileCard">
            <div style={{ margin: '20px auto' }}>
              <Avatar
                src={IlhamAvatar}
                variant="circular"
                style={{ width: '180px', height: '180px', margin: '5px auto' }}
              />
              <h3>Ilham Fadhil</h3>
              <p style={{ maxWidth: '80%', textAlign: 'center' }}>
                {' '}
                I'm a Full Stack Web Developer focused on creating fast,
                responsive and dynamic applications. I play pc games, ride a
                bike, read mangas, write blog, or learn new Web stacks on my
                free time.
              </p>
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '80%',
                  margin: 'auto'
                }}
              >
                <a
                  className="ProfileSocial"
                  href="https://twitter.com/ilhamfadheel"
                >
                  <Twitter size="2em" />
                </a>
                <a
                  className="ProfileSocial"
                  href="https://instagram.com/ilhamfadheel"
                >
                  <Instagram size="2em" />
                </a>
                <a
                  className="ProfileSocial"
                  href="https://open.spotify.com/user/12159924014?si=PnFFYa0sRAGcpb4mQFy66w"
                >
                  <Spotify size="2em" />
                </a>
                <a
                  className="ProfileSocial"
                  href="https://www.facebook.com/Ilhamfadheel/"
                >
                  <Facebook size="2em" />
                </a>
                <a
                  className="ProfileSocial"
                  href="https://www.linkedin.com/in/ilhamfadheel/"
                >
                  <LinkedinBox size="2em" />
                </a>
                <a
                  className="ProfileSocial"
                  href="https://github.com/ilhamfadheel"
                >
                  <Github size="2em" />
                </a>
              </div>
            </div>
          </div>
          <div className="ProfileCard">
            <div style={{ margin: '10px 0' }}>
              <h3>Front End</h3>
              <div className="flexDiv">
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Reactjs
                      size="2em"
                      color="lightblue"
                      className="blackBackground"
                    />
                    <p>ReactJS</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Redux size="2em" color="purple" />
                    <p>Redux</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Html5 size="2em" color="orange" />
                    <p>HTML5</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Css3 size="2em" color="blue" />
                    <p>CSS</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Javascript size="2em" color="gold" />
                    <p>Javascript</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Bootstrap
                      size="2em"
                      color="lightblue"
                      className="blueviolet"
                    />
                    <p>Bootstrap</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Tailwindcss size="2em" color="var(--primary)" />
                    <p>TailwindCSS</p>
                  </div>
                </div>
              </div>
              <h3>Back End</h3>
              <div className="flexDiv">
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Php size="2em" color="mediumpurple" />
                    <p>PHP</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Spring size="2em" color="mediumseagreen" />
                    <p>SpringAPI</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Graphql size="2em" color="deeppink" />
                    <p>Graphql</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Nodejs size="2em" color="mediumseagreen" />
                    <p>Nodejs</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Mysql size="2em" color="darkblue" />
                    <p>MySQL</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Apache size="2em" color="purple" />
                    <p>Apache</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Nginx size="2em" color="forestgreen" />
                    <p>Nginx</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Linux size="2em" color="#001d34" />
                    <p>Linux</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Mongodb size="2em" color="green" />
                    <p>Mongodb</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ProfileCard">
            <div style={{ margin: '10px 0' }}>
              <h3>Framework & CMS</h3>
              <div className="flexDiv">
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Gatsby size="2em" color="purple" />
                    <p>Gatsbyjs</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <NextDotJs size="2em" color="black" />
                    <p>NextJS</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Netlify size="2em" color="turquoise" />
                    <p>Netlify</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Wordpress size="2em" color="#117ac9" />
                    <p>Wordpress</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <span
                      className="iconify"
                      data-icon="logos-contentful"
                      data-inline="false"
                      style={{
                        height: '2em',
                        width: '2em',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        overflow: 'hidden'
                      }}
                    ></span>
                    <p>Contentful</p>
                  </div>
                </div>
              </div>
              <h3>Tools & Deployment</h3>
              <div className="flexDiv">
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Git size="2em" color="orange" />
                    <p>Git</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Adobephotoshop size="2em" color="#001d34" />
                    <p>Photoshop</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Npm size="2em" color="red" />
                    <p>NPM</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Yarn size="2em" color="steelblue" />
                    <p>Yarn</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Amazonaws
                      size="2em"
                      color="white"
                      className="blackBackground"
                    />
                    <p>AWS</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Googlecloud size="2em" color="royalblue" />
                    <p>GCP</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Heroku size="2em" color="indigo" />
                    <p>Heroku</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Vercel size="2em" color="black" />
                    <p>Vercel</p>
                  </div>
                </div>
              </div>
              <div>
                <h3>
                  Libraries / Plugins & <br />
                  Programming Languages
                </h3>
              </div>
              <div className="flexDiv">
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <span
                      className="iconify"
                      data-icon="logos:python"
                      data-inline="false"
                      style={{
                        height: '2em',
                        width: '2em',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        overflow: 'hidden'
                      }}
                    ></span>
                    <p>Python</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Woo size="2em" color="#96598b" />
                    <p>Commerce</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Java size="2em" color="steelblue" />
                    <p>Java</p>
                  </div>
                </div>
                <div className="skillIcons">
                  <div className="ProfileSocial">
                    <Cplusplus size="2em" color="steelblue" />
                    <p>C++</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default ProfileSection
